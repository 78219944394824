/*
 * WTF, forms?
 * Released under MIT and copyright 2014 Mark Otto.
 * http://wtfforms.com
 *
 * Embedded icons from Open Iconic.
 * Released under MIT and copyright 2014 Waybury.
 * http://useiconic.com/open
 */



/*
 * Checkboxes and radios
 */

 .control {
    position: relative;
    display: inline-block;
    padding-left: 1.5rem;
    color: #555;
    cursor: pointer;
  }
  .control input {
    position: absolute;
    opacity: 0;
    z-index: -1; /* Put the input behind the label so it doesn't overlay text */
  }
  .control-indicator {
    position: absolute;
    top: .25rem;
    left: 0;
    display: block;
    width:  1rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 65%;
    color: #eee;
    text-align: center;
    background-color: #eee;
    background-size: 50% 50%;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  /* Hover state */
  /* Uncomment if you need it, but be aware of the sticky iOS states.
  .control:hover .control-indicator {
    color: #fff;
    background-color: #ccc;
  }
  */
  
  /* Focus */
  .control input:focus ~ .control-indicator {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
  }
  
  /* Checked state */
  .control input:checked ~ .control-indicator {
    color: #fff;
    background-color: #0074d9;
  }
  
  /* Active */
  .control input:active ~ .control-indicator {
    color: #fff;
    background-color: #84c6ff;
  }
  
  /* Checkbox modifiers */
  .checkbox .control-indicator {
    border-radius: .25rem;
  }
  .checkbox input:checked ~ .control-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  }
  
  /* Radio modifiers */
  .radio .control-indicator {
    border-radius: 50%;
  }
  .radio input:checked ~ .control-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==);
  }
  
  /* Alternately, use another character */
  .control-x input:checked ~ .control-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xLjQsMEwwLDEuNGwwLjcsMC43bDEuOCwxLjhMMC43LDUuN0wwLDYuNGwxLjQsMS40bDAuNy0wLjdsMS44LTEuOGwxLjgsMS44bDAuNywwLjdsMS40LTEuNEw3LjEsNS43DQoJTDUuMywzLjlsMS44LTEuOGwwLjctMC43TDYuNCwwTDUuNywwLjdMMy45LDIuNUwyLjEsMC43QzIuMSwwLjcsMS40LDAsMS40LDB6Ii8+DQo8L3N2Zz4NCg==);
  }
  .control-dash input:checked ~ .control-indicator {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K)
  }
  
  
  
  /*
   * Select
   */
  
  .select {
    position: relative;
    display: inline-block;
    color: #555;
  }
  .select select {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: .5rem 2.25rem .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: 0;
    border-radius: .25rem;
    cursor: pointer;
    outline: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  /* Undo the Firefox inner focus ring */
  .select select:focus:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  /* Dropdown arrow */
  .select:after {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    margin-top: -.15rem;
    pointer-events: none;
    border-top: .35rem solid;
    border-right: .35rem solid transparent;
    border-bottom: .35rem solid transparent;
    border-left: .35rem solid transparent;
  }
  
  /* Hover state */
  /* Uncomment if you need it, but be aware of the sticky iOS states.
  .select select:hover {
    background-color: #ddd;
  }
  */
  
  /* Focus */
  .select select:focus {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
  }
  
  /* Active/open */
  .select select:active {
    color: #fff;
    background-color: #0074d9;
  }
  
  /* Hide the arrow in IE10 and up */
  .select select::-ms-expand {
    display: none;
  }
  
  /* Media query to target Firefox only */
  @-moz-document url-prefix() {
    /* Firefox hack to hide the arrow */
    .select select {
      text-indent: 0.01px;
      text-overflow: '';
      padding-right: 1rem;
    }
  
    /* <option> elements inherit styles from <select>, so reset them. */
    .select option {
      background-color: white;
    }
  }
  
  /* IE9 hack to hide the arrow */
  @media screen and (min-width:0\0) {
    .select select {
      z-index: 1;
      padding: .5rem 1.5rem .5rem 1rem;
    }
    .select:after {
      z-index: 5;
    }
    .select:before {
      position: absolute;
      top: 0;
      right: 1rem;
      bottom: 0;
      z-index: 2;
      content: "";
      display: block;
      width: 1.5rem;
      background-color: #eee;
    }
    .select select:hover,
    .select select:focus,
    .select select:active {
      color: #555;
      background-color: #eee;
    }
  }
  
  
  
  /*
   * File
   */
  
  .file {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;
  }
  .file input {
    min-width: 14rem;
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
  }
  .file-custom {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: .075rem solid #ddd;
    border-radius: .25rem;
    box-shadow: inset 0 .2rem .4rem rgba(0,0,0,.05);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file-custom:after {
    content: "Choose files";
  }
  .file-custom:before {
    position: absolute;
    top: -.075rem;
    right: -.075rem;
    bottom: -.075rem;
    z-index: 6;
    display: block;
    content: "Browse";
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: .075rem solid #ddd;
    border-radius: 0 .25rem .25rem 0;
  }

  .file-button {
    display: inline-block;
    position: relative;
    top: 0.72rem;
    left: 1rem;
  }

  .file-button:before {
    /* position: absolute;
    top: 0.03rem;
    right: -6rem; */
    /* bottom: -.076rem; */
    z-index: 6;
    display: block;
    content: "Upload";
    height: 2.5rem;
    padding: .5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #eee;
    border: .075rem solid #ddd;
    border-radius: .25rem;
    cursor: pointer;
  }
  
  /* Focus */
  .file input:focus ~ .file-custom {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #0074d9;
  }
  
  
  
  /*
   * Progress
   */
  
  .progress {
    display: inline-block;
    height: 1rem;
  }
  .progress[value] {
    /* Reset the default appearance */
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    /* Remove Firefox and Opera border */
    border: 0;
    /* IE10 uses `color` to set the bar background-color */
    color: #0074d9;
  }
  .progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: .2rem;
  }
  .progress[value]::-webkit-progress-value {
    background-color: #0074d9;
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
  }
  .progress[value="100"]::-webkit-progress-value {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
  }
  
  /* Firefox styles must be entirely separate or it busts Webkit styles. */
  @-moz-document url-prefix() {
    .progress[value] {
      background-color: #eee;
      border-radius: .2rem;
    }
    .progress[value]::-moz-progress-bar {
      background-color: #0074d9;
      border-top-left-radius: .2rem;
      border-bottom-left-radius: .2rem;
    }
    .progress[value="100"]::-moz-progress-bar {
      border-top-right-radius: .2rem;
      border-bottom-right-radius: .2rem;
    }
  }
  
  /* IE9 hacks to accompany custom markup. We don't need to scope this via media queries, but I feel better doing it anyway. */
  @media screen and (min-width:0\0) {
    .progress {
      background-color: #eee;
      border-radius: .2rem;
    }
    .progress-bar {
      display: inline-block;
      height: 1rem;
      text-indent: -999rem; /* Simulate hiding of value as in native `<progress>` */
      background-color: #0074d9;
      border-top-left-radius: .2rem;
      border-bottom-left-radius: .2rem;
    }
    .progress[width="100%"] {
      border-top-right-radius: .2rem;
      border-bottom-right-radius: .2rem;
    }
  }
  
  
  /*
   * Control layouts
   */
  
  .controls-stacked {
    margin: 1rem 0;
  }
  .controls-stacked .control,
  .controls-stacked .progress {
    display: block;
  }
  .controls-stacked .control + .control,
  .controls-stacked .progress + .progress {
    margin-top: .5rem;
  }
  
  .controls-inline {
    margin: 1rem 0;
  }
  .controls-inline .control {
    display: inline-block;
    height: 1rem;
  }
  .controls-inline .control + .control {
    margin-left: 1rem;
  }