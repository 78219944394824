@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap');

* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: unset;
}
p {
  overflow: hidden;
  text-overflow: ellipsis;
}

body {
  margin: 0;
  font-family: 'Barlow';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

.app-notification {
  font-family: 'Barlow' !important;
  font-weight: 400 !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Barlow' !important;
  font-weight: 700 !important;
}

h6 {
  font-family: 'Barlow' !important;
  font-weight: 500 !important;
}

code {
  font-family: 'Barlow' !important;
  font-weight: 400 !important;
}

.fileNames label {
  display: block;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color:#38A42E;
}

.userName{
  font-size: 13px;
  font-weight: bold;
  position: relative;
  bottom: 5px;
}

.logo img{
  max-width: 220px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container-header {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
}

ul {
  display: block;
  margin-left: -10px;
}

ul li {
  display: block;
  position: relative;
  color: #334a50;
}

ul li:not(:last-child) {
  margin-bottom: 16px;
}

ul li:before {
  content: "";
  position: absolute;
  top: 1.2em;
  left: -30px;
  margin-top: -.9em;
  background: #f0b8a3;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.logout-btn {
  position: relative !important;
  left: 35%;
  top: -50px;
}

@media (max-width: 575px) {
  .container {
    padding: 20px !important;
  }

  .logout-btn {
    left: 75%;
    top: -125px;
  }
}
